export class CardanoConnect {
  static readonly type = '[Wallet] Cardano connect';
  constructor(public walletName: string) {}
}

export class GetNonce {
  static readonly type = '[Wallet] Get nonce';
  constructor(
    public address: string,
    public blockchain: string,
  ) {}
}

export class GetToken {
  static readonly type = '[Wallet] Get token';
  constructor(
    public blockchain: string,
    public address: string,
    public signature: any,
  ) {}
}

export class ConnectMetamask {
  static readonly type = '[Wallet] Connect metamask';
}

export class GetCryptoToken {
  static readonly type = '[Wallet] Get crypto token';
  constructor(
    public address: string,
    public nonce: string,
  ) {}
}

export class SetAddress {
  static readonly type = '[Wallet] Set address';
  constructor(public address: string) {}
}

export class GetTokenMetamask {
  static readonly type = '[Wallet] Get token metamask';
  constructor(
    public blockchain: string,
    public address: string,
    // public walletName: string,
    public signature: any,
  ) {}
}

export class ConnectMetamask2 {
  static readonly type = '[Wallet] Connect metamask2';
}

export class Claim {
  static readonly type = '[Wallet] Claim';
  constructor(
    public taskId: string,
    public address: string,
  ) {}
}

export class CreateContract {
  static readonly type = '[Wallet] Create contract';
  constructor(
    public contractAddress: string,
    public abi: [],
    public address: string,
    public amount: string,
    public signature: string,
  ) {}
}
