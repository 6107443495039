export interface WalletStateModel {
  account: string;
}

export interface GetNonceResponse {
  status: true;
  nonce: string;
}

export interface GetTokenResponse {
  status: boolean;
  id: string;
  address: string;
  token: string;
}

export interface ClaimResponse {
  status: boolean;
  amount: string;
  signature: string;
  blockchain: string;
  data: {
    address: string;
    abi: [
      {
        inputs: [
          {
            internalType: string;
            name: string;
            type: string;
          },
        ];
        name: string;
        outputs: [
          {
            internalType: string;
            name: string;
            type: string;
          },
        ];
        stateMutability: string;
        type: string;
      },
      {
        inputs: [
          {
            internalType: string;
            name: string;
            type: string;
          },
          {
            internalType: string;
            name: string;
            type: string;
          },
        ];
        name: string;
        outputs: [];
        stateMutability: string;
        type: string;
      },
    ];
  };
  receiver: {
    address: string;
    blockchain: string;
  };
}
