export interface CoreStateModel {}

export interface PaymentCardanoResponse {
  status: boolean;
  data: {
    metadata: {
      validator: string;
      payment_id: string;
    };
    receiver_address: string;
  };
}

export interface GetNftsResponse {
  status: boolean;
  total: {
    value: string;
    decimals: string;
    formatted: string;
  };
  assets: AssetNft[];
  task: {
    id: string;
    status: string;
  };
}

export interface AssetNft {
  id: string;
  type: string;
  name: string;
  symbol: null;
  image: string;
  description: string;
  decimals: 0;
  quantity: string;
  blockchain: string;
  data: {
    network: string;
    policy_id: string;
    asset_name: string;
  };
  collection: {
    owned: number;
  };
  rarity: string;
  output: {
    value: string;
    decimals: string;
    formatted: string;
  };
}

export interface GetTokensResponse {
  status: boolean;
  total: {
    value: string;
    decimals: string;
    formatted: string;
  };
  assets: AssetToken[];
}

export interface AssetToken {
  id: string;
  type: string;
  name: string;
  symbol: string;
  decimals: number;
  description: string;
  quantity: {
    value: string;
    formatted: string;
  };
  blockchain: string;
  data: {
    network: string;
    policy_id: string;
  };
  output: {
    value: string;
    decimals: string;
    formatted: string;
  };
}
