import { Selector } from '@ngxs/store';
import { WalletState } from './wallet.state';
import { ClaimResponse } from './wallet-state.model';

export class WalletSelectors {
  @Selector([WalletState])
  static amount(state: ClaimResponse): string {
    return state.amount;
  }

  @Selector([WalletState])
  static signature(state: ClaimResponse): string {
    return state.signature;
  }

  @Selector([WalletState])
  static data(state: ClaimResponse): any {
    return state.data;
  }
}
