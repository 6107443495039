export class PaymentCardano {
  static readonly type = '[Core] Payment cardano';
  constructor(public orderId: string) {}
}

export class GetNfts {
  static readonly type = '[Core] Get nfts';
}

export class GetTokens {
  static readonly type = '[Core] Get tokens';
}
