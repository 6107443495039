import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  defer,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  ClaimResponse,
  ConnectMetamask,
  GetCryptoToken,
  GetNonce,
  GetNonceResponse,
  GetTokenMetamask,
  GetTokenResponse,
  SetAddress,
} from '../store/wallet';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { logCumulativeDurations } from '@angular-devkit/build-angular/src/tools/esbuild/profiling';
import { Contract } from 'web3-eth-contract';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private walletAddressSubject: BehaviorSubject<string | null>;
  public walletAddress$: Observable<string | null>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
  ) {
    this.initEthereumProvider();

    const walletAddress = localStorage.getItem('walletAddress');
    this.walletAddressSubject = new BehaviorSubject<string | null>(
      walletAddress,
    );
    this.walletAddress$ = this.walletAddressSubject.asObservable();
  }

  /* ------------------------------- Wallet address ------------------------------- */

  isWalletAddress(): boolean {
    return this.getWalletAddress() !== null;
  }

  getWalletAddress(): string | null {
    return this.walletAddressSubject.getValue();
  }

  setWalletAddress(walletAddress: string | null): void {
    this.walletAddressSubject.next(walletAddress);
  }

  /* ------------------------------- Wallet name ------------------------------- */

  isWalletName(): boolean {
    return this.getWalletName() !== null;
  }

  getWalletName(): string | null {
    return localStorage.getItem('walletName');
  }

  /* ------------------------------- Wallet token ------------------------------- */

  isWalletToken(): boolean {
    return this.getWalletToken() !== null;
  }

  getWalletToken(): string | null {
    return localStorage.getItem('walletAuthToken');
  }

  /* ------------------------------- Disconnect wallet ------------------------------- */

  disconnectWallet(): void {
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletAuthToken');
    localStorage.removeItem('walletName');

    const currentUrl = window.location.pathname;
    if (currentUrl !== '/' && currentUrl !== '/terms-and-conditions') {
      this.router.navigate(['/']);
    }

    this.setWalletAddress('');
  }

  /* ------------------------------- Connect Metamask ------------------------------- */

  private message =
    'We need you to sign this message to verify the wallet ownership';
  provider!: any;

  projectId: string = 'ebd2944038e01a0d4d1acc50e41edde8';
  private options = {
    projectId: this.projectId,
    chains: [137],
    optionalChains: [1, 80001],
    showQrModal: true,
  } as any;

  async initEthereumProvider() {
    try {
      this.provider = await EthereumProvider.init(this.options);
    } catch (e) {
      console.log(e);
    }
  }

  get ethereum(): any {
    return (window as any).ethereum;
  }

  connectMetamask2(): any {
    let address = '';

    if (this.ethereum) {
      return defer(() =>
        this.ethereum.request({
          method: 'eth_requestAccounts',
        }),
      ).pipe(
        map((accounts: any) => {
          console.log(accounts);
          if (accounts && accounts[0] && this.ethereum) {
            return accounts[0];
          }
        }),
        mergeMap((account: string) => {
          console.log(account);
          address = account;

          return this.getNonce(address, 'ethereum');
        }),
        mergeMap((res): any => {
          console.log(res);
          return this.getCryptoToken(address, res.nonce);
        }),
      );
    } else {
      this.addEthProvider();
    }
  }

  async addEthProvider() {
    try {
      console.log(this.provider);
      this.provider.on('accountsChanged', this.getAddress);
      this.provider.on('connect', this.getSignature);
      await this.provider.connect();
    } catch (error) {
      console.error(error);
    }
  }

  getAddress = (event: any) => {
    if (event && event[0]) {
      console.log('getAddress', event);
      this.addressMetamask = event[0];
      // this.store.dispatch(new SetAddress(this.addressMetamask));
    }
  };

  addressMetamask!: string;

  getSignature = (event: any) => {
    if (event && this.addressMetamask) {
      console.log('getSignature', event);
      setTimeout(() => {
        this.store
          .dispatch(new GetNonce(this.addressMetamask, 'ethereum'))
          .subscribe((res) => {
            this.store.dispatch(
              new GetCryptoToken(this.addressMetamask, res.wallet.nonce),
            );
          });
      }, 1000);
    }
  };

  getCryptoToken(address: string, nonce: string): any {
    const message = `${this.message}, nonce:${nonce}`;
    const params = [address, message];

    // If MetaMask plugin available
    if (this.ethereum) {
      return defer(() =>
        this.ethereum.request({
          method: 'personal_sign',
          params: params,
        }),
      ).pipe(
        mergeMap((signature: any) => {
          localStorage.setItem('claimAddress', address);
          return this.getTokenMetamask('ethereum', address, signature);
        }),
      );
    }
    // If no MetaMask ethereum provider
    else {
      // const msg = `0x${Buffer.from(this.message, 'utf8').toString('hex')}`;
      const encoder = new TextEncoder();
      const msgBytes = encoder.encode(message);
      const msgHex = Array.from(new Uint8Array(msgBytes.buffer))
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('');

      const msg = `0x${msgHex}`;

      console.log([msg, address]);
      return defer(() =>
        this.provider.request({
          method: 'personal_sign',
          params: [msg, address],
        }),
      ).pipe(
        mergeMap((signature: any) => {
          localStorage.setItem('claimAddress', address);
          return this.getTokenMetamask('ethereum', address, signature);
        }),
      );
    }
  }

  getTokenMetamask(
    blockchain: string,
    address: string,
    signature: any,
  ): Observable<any> {
    return this.http.post(`${environment.apiUrl}/crypto-wallet`, {
      bot_id: environment.botId,
      blockchain: blockchain,
      address: address,
      signature: signature,
      message: `${this.message}`,
    });
  }

  /* ------------------------------- End connect Metamask ------------------------------- */

  claimNew(address: string, blockchain: string) {
    return this.http
      .post<GetNonceResponse>(
        `${environment.apiUrl}/crypto-wallet/verification/nonce`,
        {
          address: address,
          message:
            'We need you to sign this message to verify the wallet ownership ',
          blockchain: blockchain,
        },
      )
      .pipe(
        mergeMap((res) => {
          return this.getCryptoToken(address, res.nonce);
        }),
      );
  }

  connectMetamask() {
    if (this.ethereum) {
      this.store.dispatch(new ConnectMetamask());
    } else {
      this.addEthProvider();
    }
  }

  connectWallet(): any {
    // localStorage.removeItem('walletconnect');

    // If MetaMask plugin available
    return defer(() =>
      this.ethereum.request({
        method: 'eth_requestAccounts',
      }),
    );
  }

  /* ------------------------------- Auth ------------------------------- */

  getNonce(address: string, blockchain: string): Observable<GetNonceResponse> {
    return this.http.post<GetNonceResponse>(
      `${environment.apiUrl}/crypto-wallet/verification/nonce`,
      {
        address: address,
        message: `${this.message} `,
        blockchain: blockchain,
      },
    );
  }

  getToken(
    blockchain: string,
    address: string,
    signature: any,
  ): Observable<GetTokenResponse> {
    return this.http.post<GetTokenResponse>(
      `${environment.apiUrl}/crypto-wallet`,
      {
        bot_id: environment.botId,
        blockchain: blockchain,
        address: address,
        signature: signature,
      },
    );
  }

  /* ------------------------------- Contract ------------------------------- */

  claim(taskId: string, address: string): Observable<ClaimResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.post<ClaimResponse>(
      `${environment.apiUrl}/tool/${environment.botId}/asset-conversion/${taskId}/claim`,
      {
        receiver: {
          address: address,
        },
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  get getProvider() {
    return this.ethereum ? (window as any).ethereum : this.provider;
  }

  createContract(
    contractAddress: string,
    abi: [],
    address: string,
    amount: string,
    signature: string,
  ): Observable<any> {
    const contract = new Contract(abi, contractAddress);
    contract.provider = this.getProvider;

    return defer(() =>
      (contract.methods as any).claim(amount, signature).send({
        from: address,
      }),
    ).pipe(
      map((res) => res),
      catchError((error) => {
        return of({ error: error.message });
      }),
    );
  }

  /* ------------------------------- ++ ------------------------------- */
}
