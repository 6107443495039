import { Selector } from '@ngxs/store';
import { CoreState } from './core.state';
import {
  AssetNft,
  AssetToken,
  GetNftsResponse,
  GetTokensResponse,
  PaymentCardanoResponse,
} from './core-state.model';

export class CoreSelectors {
  @Selector([CoreState])
  static receiver_address(state: PaymentCardanoResponse): string {
    return state.data.receiver_address;
  }

  @Selector([CoreState])
  static metadata(state: PaymentCardanoResponse): {
    validator: string;
    payment_id: string;
  } {
    return state.data.metadata;
  }

  @Selector([CoreState])
  static totalFormatted(state: GetNftsResponse): string {
    return state.total.formatted;
  }

  @Selector([CoreState])
  static assets(state: GetNftsResponse): AssetNft[] {
    return state.assets;
  }

  @Selector([CoreState])
  static statusGetListNfts(state: GetNftsResponse): boolean {
    return state.status;
  }

  @Selector([CoreState])
  static assetTokens(state: GetTokensResponse): AssetToken[] {
    return state.assets;
  }

  @Selector([CoreState])
  static task(state: GetNftsResponse): { id: string; status: string } {
    return state.task;
  }
}
