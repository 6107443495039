import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  CoreStateModel,
  GetNftsResponse,
  GetTokensResponse,
  PaymentCardanoResponse,
} from './core-state.model';
import { GetNfts, GetTokens, PaymentCardano } from './core.actions';
import { CoreService } from '../../services/core.service';
import { Observable, tap } from 'rxjs';

@State<CoreStateModel>({
  name: 'core',
})
@Injectable()
export class CoreState {
  constructor(
    private coreService: CoreService,
    private store: Store,
  ) {}

  @Action(PaymentCardano)
  paymentCardano(
    { patchState }: StateContext<PaymentCardanoResponse>,
    { orderId }: PaymentCardano,
  ): Observable<PaymentCardanoResponse> {
    return this.coreService.paymentCardano(orderId).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            data: res.data,
          });
        }
      }),
    );
  }

  @Action(GetNfts)
  getListNfts(
    { patchState }: StateContext<GetNftsResponse>,
    {}: GetNfts,
  ): Observable<GetNftsResponse> {
    patchState({ status: undefined, total: undefined, assets: undefined });

    return this.coreService.getNfts().pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            status: res.status,
            total: res.total,
            assets: res.assets,
            task: res.task,
          });
        }
      }),
    );
  }

  @Action(GetTokens)
  getTokens(
    { patchState }: StateContext<GetTokensResponse>,
    {}: GetTokens,
  ): Observable<GetTokensResponse> {
    return this.coreService.getTokens().pipe(
      tap((res) => {
        if (res) {
          patchState({
            status: res.status,
            assets: res.assets,
          });
        }
      }),
    );
  }
}
