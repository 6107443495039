import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  ClaimResponse,
  GetNonceResponse,
  GetTokenResponse,
  WalletStateModel,
} from './wallet-state.model';
import {
  CardanoConnect,
  Claim,
  ConnectMetamask,
  ConnectMetamask2,
  CreateContract,
  GetCryptoToken,
  GetNonce,
  GetToken,
  GetTokenMetamask,
  SetAddress,
} from './wallet.actions';
import { BrowserWallet } from '@meshsdk/core';
import { NotificationService } from '../../services/notification.service';
import { WalletService } from '../../services/wallet.service';
import { debounce, Observable, tap } from 'rxjs';
import { logCumulativeDurations } from '@angular-devkit/build-angular/src/tools/esbuild/profiling';
import { patch } from '@ngxs/store/operators';

@State<WalletStateModel>({
  name: 'wallet',
})
@Injectable()
export class WalletState {
  constructor(
    private notification: NotificationService,
    private walletService: WalletService,
    private store: Store,
    private zone: NgZone,
  ) {}

  /* ------------------------------- Connect Cardano ------------------------------- */

  @Action(CardanoConnect)
  async cardanoConnect(
    {}: StateContext<WalletStateModel>,
    { walletName }: CardanoConnect,
  ): Promise<void> {
    try {
      const wallet = await BrowserWallet.enable(walletName);
      const address = await wallet.getChangeAddress();
      const rewardAddresses = (await wallet.getRewardAddresses())[0];

      if (address && rewardAddresses) {
        this.store
          .dispatch(new GetNonce(address, 'cardano'))
          .subscribe((res) => {
            if (res) {
              this.zone.run(() => {});
              wallet.signData(rewardAddresses, res.wallet.nonce).then((res) => {
                if (res) {
                  this.store
                    .dispatch(new GetToken('cardano', address, res))
                    .subscribe(() => {
                      this.walletService.setWalletAddress(address);
                      localStorage.setItem('walletAddress', address);
                      localStorage.setItem('walletName', walletName);
                    });
                }
              });
            }
          });
      }
    } catch (error) {
      this.notification.error(
        'Eternl extension not found. Please, install it.',
      );
    }
  }

  /* ------------------------------- Connect Metamask ------------------------------- */

  @Action(ConnectMetamask2)
  connectMetamask2({}: StateContext<any>, {}: ConnectMetamask2) {
    return this.walletService.connectMetamask2().pipe();
  }

  @Action(ConnectMetamask)
  connectWallet({
    patchState,
    dispatch,
  }: StateContext<WalletStateModel>): Observable<any> {
    return this.walletService.connectWallet().pipe(
      tap((accounts: string[]) => {
        // console.log(accounts[0]);

        if (accounts && accounts[0] && this.walletService.ethereum) {
          patchState({ account: accounts[0] });
          // return this.walletService.claimNew(accounts[0], 'ethereum').pipe(
          //     tap((res) => {
          //       console.log(res);
          //     })
          // );

          this.store
            .dispatch(new GetNonce(accounts[0], 'ethereum'))
            .subscribe((res) => {
              if (res) {
                dispatch(new GetCryptoToken(accounts[0], res.wallet.nonce));
              }
            });
        }
      }),
    );
  }

  @Action(GetCryptoToken)
  getCryptoToken(
    { patchState, dispatch }: StateContext<WalletStateModel>,
    { address, nonce }: GetCryptoToken,
  ): Observable<any> {
    return this.walletService.getCryptoToken(address, nonce).pipe(
      tap((res: any) => {
        if (res.status) {
          patchState({ account: address });
          // localStorage.setItem('walletAuthToken', res.token);
          // localStorage.setItem('walletAddress', res.address);

          // dispatch(GetAccount);
        }
      }),
      // catchError((e: any) => {
      // return throwError(e);
      // }),
    );
  }

  @Action(SetAddress)
  setCurrentForm(
    { patchState }: StateContext<WalletStateModel>,
    { address }: SetAddress,
  ): any {
    patchState({
      account: address,
    });
  }

  /* ------------------------------- Auth ------------------------------- */

  @Action(GetNonce)
  async getNonce(
    { patchState }: StateContext<GetNonceResponse>,
    { address, blockchain }: GetNonce,
  ): Promise<Observable<GetNonceResponse>> {
    return this.walletService.getNonce(address, blockchain).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            nonce: res.nonce,
          });
        }
      }),
    );
  }

  @Action(GetToken)
  getToken(
    {}: StateContext<GetTokenResponse>,
    { blockchain, address, signature }: GetToken,
  ) {
    return this.walletService.getToken(blockchain, address, signature).pipe(
      tap((res) => {
        if (res.status) {
          localStorage.setItem('walletAuthToken', res.token);
        }
      }),
    );
  }

  /* ------------------------------- Contract ------------------------------- */

  @Action(Claim)
  claim(
    { patchState }: StateContext<ClaimResponse>,
    { taskId, address }: Claim,
  ): Observable<ClaimResponse> {
    return this.walletService.claim(taskId, address).pipe(
      tap((res) => {
        if (res) {
          patchState({
            status: res.status,
            amount: res.amount,
            signature: res.signature,
            data: res.data
          })
        }
      }),
    );
  }

  @Action(CreateContract)
  createContract(
    {}: StateContext<WalletStateModel>,
    { contractAddress, abi, address, amount, signature }: CreateContract,
  ): Observable<any> {
    return this.walletService
      .createContract(contractAddress, abi, address, amount, signature)
      .pipe();
  }

  /* ------------------------------- ++ ------------------------------- */
}
