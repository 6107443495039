import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  GetNftsResponse,
  GetTokensResponse,
  PaymentCardanoResponse,
} from '../store/core';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  token = this.walletService.getWalletToken();

  constructor(
    private http: HttpClient,
    private walletService: WalletService,
  ) {}

  paymentCardano(orderId: string): Observable<PaymentCardanoResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.post<PaymentCardanoResponse>(
      `${environment.apiUrl}/api/customer/payment/prepare/cardano`,
      {
        order_id: orderId,
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  getNfts(): Observable<GetNftsResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.get<GetNftsResponse>(
      `${environment.apiUrl}/tool/${environment.botId}/asset-conversion/estimate?type=nft`,
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  getTokens(): Observable<GetTokensResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.get<GetTokensResponse>(
      `${environment.apiUrl}/tool/${environment.botId}/asset-conversion/estimate?type=token`,
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }
}
